const accents = require('remove-accents');

const isNumber = function(n)
{
	return !isNaN(parseFloat(n)) && isFinite(n);
},
isObject = function(value)
{
	return typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null;
},
Authorization = (allowedRoles, role) => {
  
    if(allowedRoles.includes(role))
      	return true;
    
    return false;
},
isDate = (n) => {
	return Date.parse(n);
},
isString = (str) => {

	if (typeof str === 'string' || str instanceof String)
		return true;
	else
		return false;
},
isBoolean = variable => {
	if (typeof variable === 'boolean')
		return true;
	else 
		return false;
};

module.exports = {
	'errors': {
		accommodations: {
			contact: {
				name: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					return {check: false, message: ""};
				},
				surname: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					return {check: false, message: ""};
				},
				email: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const patern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
					if(!patern.test(value))
						return {check: true, message: messages?.validEmail ? messages?.validEmail.word : undefined};

					return {check: false, message: ""};
				},
				telefon: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validPhone ? messages?.validPhone.word : undefined};

					return {check: false, message: ""};
				},
				login: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^[A-Za-z][A-Za-z0-9_]{7,29}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validLogin ? messages?.validLogin.word : undefined};

					return {check: false, message: ""};
				},
				password: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validPassword ? messages?.validPassword.word : undefined};

					return {check: false, message: ""};
				}
			},
			login: {
				login: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^[A-Za-z][A-Za-z0-9_]{7,29}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validLogin ? messages?.validLogin.word : undefined};

					return {check: false, message: ""};
				},
				password: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validPassword ? messages?.validPassword.word : undefined};

					return {check: false, message: ""};
				}
			},
			object: {
				number: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};
						
					const pattern = /^[1-9]\d*(\s*[-/]\s*[1-9]\d*)?(\s?[a-zA-Z])?/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validAdressNumber ? messages?.validAdressNumber.word : undefined};

					return {check: false, message: ""};
				},
				places: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};
						
					const pattern = /^[0-9]{0,3}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validObjectPlaces ? messages?.validObjectPlaces.word : undefined};

					return {check: false, message: ""};
				},
				street: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /[A-Za-zÀ-ÿ]/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validStreetName ? messages?.validStreetName.word : undefined};

					return {check: false, message: ""};
				},
				postalCode: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validPostalCode ? messages?.validPostalCode.word : undefined};

					return {check: false, message: ""};
				},
				taxNumber: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^[0-9\- ]{0,15}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validTaxNumber ? messages?.validTaxNumber.word : undefined};

					return {check: false, message: ""};
				},
				name: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					return {check: false, message: ""};
				},
				location: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validLocation ? messages?.validLocation.word : undefined};

					return {check: false, message: ""};
				},
				province: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validProvince ? messages?.validProvince.word : undefined};

					return {check: false, message: ""};
				},
				price: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^\d{1,8}(?:\.\d{1,4})?$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validPrice ? messages?.validPrice.word : undefined};

					return {check: false, message: ""};
				},
				typeAlt: (type, typeAlt, messages) => {

					if(type == typeAlt)
						return {check: true, message: messages?.validTypeAlt ? messages?.validTypeAlt.word : undefined};

					return {check: false, message: ""};
				}
			},
			seasons: {
				name: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					return {check: false, message: ""};
				},
				date: (dateFrom, dateTo, messages) => {
					
					if(dateFrom === undefined || dateFrom === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};
					
					if(dateTo === undefined || dateTo === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};
					
					const pattern = /^\d{4}-\d{2}-\d{2}$/;
					if(!pattern.test(dateFrom))
						return {check: true, message: messages?.validObjectSeasonsDate? messages.validObjectSeasonsDate.word : undefined};

					
					if(!pattern.test(dateTo))
						return {check: true, message: messages?.validObjectSeasonsDate ? messages.validObjectSeasonsDate.word : undefined};

					return {check: false, message: ""};
				},
				precent: (value, messages) => {
					
					if(value === undefined || value === "" || value === null)
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^[0-9]{0,3}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validObjectSeasonsPrecent ? messages.validObjectSeasonsPrecent.word : undefined};

					return {check: false, message: ""};
				},
				color: (value, messages) => {
					
					if(value === undefined || value === "")
						return {check: true, message: messages?.required ? messages?.required.word : undefined};

					const pattern = /^#([0-9a-fA-F]{3}){1,2}$/;
					if(!pattern.test(value))
						return {check: true, message: messages?.validObjectSeasonsColor ? messages.validObjectSeasonsColor.word : undefined};

					return {check: false, message: ""};
				}
			}
		}
	},
	'metatagDescription': function(description){

		const desc = description.slice(0,160);
		const index = desc.lastIndexOf(" ");

		return desc.slice(0,index);
	},
	'pagesItems': function({ items, limit, page }){

		const result = {},
		pages = Math.ceil(items.length / limit);
	
		let inc = 0;
		for (inc; inc < pages; inc++) {
			const index = isNumber(page) ? (parseInt(page) + inc) : (inc + 1);
			result[index] = items.slice(inc * limit, (inc + 1) * limit);
		}
	
		return result;
	},
	'limitItemsMap': 60,
	'limitItems': 15,
	'limitItemsList': 15,
	'capitalize': function(string)
	{
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	'capitalizeWords': (str) => {
		return str.split(' ')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	},
	'removeFirstAndLastCharacter': str => str.slice(1, -1),
	'stripTags': function(html)
	{
		return html.replace(/(<([^>]+)>)/ig,"");
	},
	'isNumber': isNumber,
	'isString': isString,
	'isObject': isObject,
	'isDate': isDate,
	'isBoolean': isBoolean,
	'getSeasonPrice': (seasons, prices) => {

		const season = seasons.filter((season) => moment().isBetween(
			moment(season.dateFrom, 'DD-MM'), 
			moment(season.dateTo, 'DD-MM'), 
			'day', 
			'[]'
		)).slice(-1)[0];

		if(!season)
			return null;

		return prices.find((price) => price.idSeason == season.id).price;
	},
	'inside': (point, vs) => {
		// ray-casting algorithm based on
		// http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

		var x = point[0], y = point[1];

		var inside = false;
		for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
			var xi = vs[i][0], yi = vs[i][1];
			var xj = vs[j][0], yj = vs[j][1];

			var intersect = ((yi > y) != (yj > y))
				&& (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
			if (intersect) inside = !inside;
		}

		return inside;
	},
	'distanceCalc': (lat1, lon1, lat2, lon2, unit) => {
		
		//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
		//:::                                                                         :::
		//:::  This routine calculates the distance between two points (given the     :::
		//:::  latitude/longitude of those points). It is being used to calculate     :::
		//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
		//:::                                                                         :::
		//:::  Definitions:                                                           :::
		//:::    South latitudes are negative, east longitudes are positive           :::
		//:::                                                                         :::
		//:::  Passed to function:                                                    :::
		//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
		//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
		//:::    unit = the unit you desire for results                               :::
		//:::           where: 'M' is statute miles (default)                         :::
		//:::                  'K' is kilometers                                      :::
		//:::                  'N' is nautical miles                                  :::
		//:::                                                                         :::
		//:::  Worldwide cities and other features databases with latitude longitude  :::
		//:::  are available at https://www.geodatasource.com                         :::
		//:::                                                                         :::
		//:::  For enquiries, please contact sales@geodatasource.com                  :::
		//:::                                                                         :::
		//:::  Official Web site: https://www.geodatasource.com                       :::
		//:::                                                                         :::
		//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
		//:::                                                                         :::
		//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

		if ((lat1 == lat2) && (lon1 == lon2)) {
			return 0;
		}
		else {
			var radlat1 = Math.PI * lat1/180;
			var radlat2 = Math.PI * lat2/180;
			var theta = lon1-lon2;
			var radtheta = Math.PI * theta/180;
			var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
			if (dist > 1) {
				dist = 1;
			}
			dist = Math.acos(dist);
			dist = dist * 180/Math.PI;
			dist = dist * 60 * 1.1515;
			if (unit=="K") { dist = dist * 1.609344 }
			if (unit=="N") { dist = dist * 0.8684 }
			return dist;
		}
	},
	'distanceFormat': function(params){

		if(params.from == 'm' && params.to == 'km')
			return (params.distance / 1000);

		if(params.from == 'km' && params.to == 'm')
			return (params.distance * 1000);

		if(params.distance > 1)
			return Math.round(params.distance * 100) / 100;
		else
			return Math.round(params.distance * 1000);
	},
	'kalman': function(location, lastLocation, constant){

		const _toRadians = number => number * Math.PI / 180;
		const _calculateGreatCircleDistance = (locationA, locationZ) => {
			const lat1 = locationA.latitude;
			const lon1 = locationA.longitude;
			const lat2 = locationZ.latitude;
			const lon2 = locationZ.longitude;
		  
			// DOCUMENTATION: http://www.movable-type.co.uk/scripts/latlong.html
			const p1 = _toRadians(lat1);
			const p2 = _toRadians(lat2);
			const deltagamma = _toRadians(lon2 - lon1);
			const R = 6371e3; // gives d in metres
			const d =
			  Math.acos(
				Math.sin(p1) * Math.sin(p2) + Math.cos(p1) * Math.cos(p2) * Math.cos(deltagamma)
			  ) * R;
		  
			return isNaN(d) ? 0 : d;
		}

		const accuracy = Math.max(location.accuracy, 1);
		const result = { ...location, ...lastLocation, variance: null };
		
		if (!lastLocation) {
		  result.variance = accuracy * accuracy;
		} else {
			const timestampInc =
				location.timestamp.getTime() - lastLocation.timestamp.getTime();
		
			if (timestampInc > 0) {
				// We can tune the velocity and particularly the coefficient at the end
				const velocity = _calculateGreatCircleDistance(location, lastLocation) / timestampInc * constant;
				result.variance += timestampInc * velocity * velocity / 1000;
			}
		
			const k = result.variance / (result.variance + accuracy * accuracy);
			result.latitude += k * (location.latitude - lastLocation.latitude);
			result.longitude += k * (location.longitude - lastLocation.longitude);
			result.variance = (1 - k) * result.variance;
		}
	  
		return {
		  ...location,
		  //..._.pick(result, ["latitude", "longitude", "variance"])
		  "latitude": result.latitude,
		  "longitude": result.longitude,
		  "variance": result.variance
		};
	},
	countCriteriaAttractions: function(types, regions, criteria){

		let chips = [];

        if(isNumber(criteria.typeFirst) && criteria.typeFirst > 0)
            chips.push({text: types[criteria.typeFirst]});
        
        if(isNumber(criteria.region) && criteria.region > 0)
            chips.push({text: regions[regions.findIndex((region) => region.id == criteria.region)].word});

		return chips;
	},
	countCriteriaAccommodations: function(types, regions, criteria, translate){

        let chips = [];

        if(isNumber(criteria.type) && criteria.type > 0)
            chips.push({text: types[criteria.type]});
        
        if(isNumber(criteria.region) && criteria.region > 0)
            chips.push({text: regions[regions.findIndex((region) => region.id == criteria.region)].word});
        
        const pictograms = Object.keys(criteria.pictograms).filter(pictogram => criteria.pictograms[pictogram] == 1);
        if(pictograms.length > 0)
            chips.push({text: `${translate.pictograms} ${pictograms.length}`});

        if(isNumber(criteria.adults) && criteria.adults > 0)
            chips.push({text: `${translate.adults} ${criteria.adults}`});

        if(isNumber(criteria.kids) && criteria.kids > 0)
            chips.push({text: `${translate.kids} ${criteria.kids}`});

        if(criteria.dateFrom != undefined)
            chips.push({text: `${translate.dateFrom} ${criteria.dateFrom}`});

        if(criteria.dateTo != undefined)
            chips.push({text: `${translate.dateTo} ${criteria.dateTo}`});

        return chips;
	},
	removeSpecialCharactersForLink: (str) => {

		return accents.remove(str).replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase().trim().replace(/\s{1,}/g, "-");
	},
	"leapYear": (year) => (year % 4 == 0 && year % 100 != 0 || year % 400 == 0),
	"addslashes": (string) => {
		return string.replace(/\\/g, '\\\\').
			replace(/\u0008/g, '\\b').
			replace(/\t/g, '\\t').
			replace(/\n/g, '\\n').
			replace(/\f/g, '\\f').
			replace(/\r/g, '\\r').
			replace(/'/g, '\\\'').
			replace(/"/g, '\\"');
	},
	"getFilteredUsers": ({ role }) => {

		const users = {
			"admin": [
				{
					id: 1,
					type: "admin"
				},
				{
					id: 2,
					type: "manager"
				},
				{
					id: 3,
					type: "employee"
				},
				{
					id: 4,
					type: "accommodationOwner"
				},
				{
					id: 5,
					type: "receptionist"
				}
			],
			"accommodationOwner": [
				{
					id: 5,
					type: "receptionist"
				}
			],
			"manager": [
				{
					id: 3,
					type: "employee"
				},
				{
					id: 4,
					type: "accommodationOwner"
				},
				{
					id: 5,
					type: "receptionist"
				}
			]
		}

		return users[role] != undefined ? users[role] : [];
	},
	"RecepcjonistPermission": (role) => Authorization(['receptionist', 'manager', 'admin'], role),
	"ManagerPermission": (role) => Authorization(['manager', 'admin'], role),
	"resetMarker": (marker) => {

        Object.keys(marker).forEach(item => marker[item] = undefined);
        return marker;
    },
	"resetPictograms": (pictograms) => {

        Object.keys(pictograms).forEach(item => pictograms[item] = 0);
        return pictograms;
    },
	"urlToCriteriaUsers": ({ searchParams }) => {

        const criteria = {};

        if(searchParams.has("phrase") && searchParams.get("phrase") !== undefined && searchParams.get("phrase").length > 0)
            criteria['phrase'] = String(searchParams.get("phrase"));
        else criteria["phrase"] = undefined;
        
		if(searchParams.has("type") && searchParams.get("type") > 0)
			criteria["type"] = parseInt(searchParams.get("type"));
        
        if(searchParams.has("page") && searchParams.get("page") > 0)
            criteria["page"] = parseInt(searchParams.get("page"));
        else
            criteria["page"] = undefined;

        return criteria;

	},
	"urlToCriteriaAttractions": ({ location, searchParams, regions, attractionsType }) => {

        const criteria = {},
        pathnameArray = location.pathname.split("/");

        if(pathnameArray.length > 0)
        {
            const region = regions !== undefined && Object.values(regions).find(region => pathnameArray.includes(region.link));

			if(region === undefined)
				criteria["region"] = undefined;
			else{
				criteria["region"] = region.id;
				criteria["regionLink"] = region.link;
				criteria["regionWord"] = region.word;
			}
        }else criteria["region"] = undefined;

        if(pathnameArray.length > 0)
        {
            const type = attractionsType !== undefined && Object.values(attractionsType).find(type => pathnameArray.includes(type.link));

            if(type === undefined)
				criteria["type"] = undefined;
			else {
				criteria["type"] = type.id;
				criteria["typeLink"] = type.link;
				criteria["typeWord"] = type.word;
			}
        }else criteria["type"] = undefined;

        if(searchParams.has("phrase") && searchParams.get("phrase") !== undefined && searchParams.get("phrase").length > 0)
            criteria['phrase'] = String(searchParams.get("phrase"));
        else criteria["phrase"] = undefined;
        
        if(
            searchParams.has("radius_marker") && searchParams.get("radius_marker") > 0
            && searchParams.has("lat_marker") && searchParams.get("lat_marker") > 0
            && searchParams.has("lng_marker") && searchParams.get("lng_marker") > 0
        )
        {
            criteria["radiusMarker"] = {
                radius: parseFloat(searchParams.get("radius_marker")),
				radiusTo: undefined,
                lat: parseFloat(searchParams.get("lat_marker")),
                lng: parseFloat(searchParams.get("lng_marker"))
            };
        }else if(
			!searchParams.has("radius_marker")
            && searchParams.has("lat_marker") && searchParams.get("lat_marker") > 0
			&& searchParams.has("lng_marker") && searchParams.get("lng_marker") > 0
		)
		{
			criteria["radiusMarker"] = {
				radius: undefined,
				radiusTo: undefined,
				lat: parseFloat(searchParams.get("lat_marker")),
				lng: parseFloat(searchParams.get("lng_marker"))
			};
		}else
			criteria["radiusMarker"] = {
				radius: undefined,
				radiusTo: undefined,
				lat: undefined,
				lng: undefined
			};
        
		if(searchParams.has("type") && searchParams.get("type") > 0)
			criteria["type"] = parseInt(searchParams.get("type"));

        
		if(searchParams.has("active"))
			criteria["active"] = parseInt(searchParams.get("active"));
        
		if(searchParams.has("region") && searchParams.get("region") > 0)
			criteria["region"] = parseInt(searchParams.get("region"));
        
        if(searchParams.has("page") && searchParams.get("page") > 0)
            criteria["page"] = parseInt(searchParams.get("page"));
        else
            criteria["page"] = undefined;

        return criteria;
	},
	"urlToCriteriaUsers": ({ searchParams }) => {

        const criteria = {};

        if(searchParams.has("phrase") && searchParams.get("phrase") !== undefined && searchParams.get("phrase").length > 0)
            criteria['phrase'] = String(searchParams.get("phrase"));
        else criteria["phrase"] = undefined;
        
		if(searchParams.has("type") && searchParams.get("type") > 0)
			criteria["type"] = parseInt(searchParams.get("type"));

        
		if(searchParams.has("active"))
			criteria["active"] = parseInt(searchParams.get("active"));
        
        if(searchParams.has("page") && searchParams.get("page") > 0)
            criteria["page"] = parseInt(searchParams.get("page"));
        else
            criteria["page"] = undefined;

        return criteria;
	},
	"urlToCriteriaAccommodations": ({ location, searchParams, regions, accommodationsType }) => {

        const criteria = {},
        pathnameArray = location.pathname.split("/");

        if(pathnameArray.length > 0)
        {
            const region = Object.values(regions).find(region => pathnameArray.includes(region.link));
            if(region === undefined)
				criteria["region"] = undefined;
			else {
				criteria["region"] = region.id;
				criteria["regionWord"] = region.word;
				criteria["regionLink"] = region.link;
			}
        }else criteria["region"] = undefined;

        if(pathnameArray.length > 0)
        {
            const type = Object.values(accommodationsType).find(type => pathnameArray.includes(type.link));
            if(type === undefined)
				criteria["type"] = undefined;
			else{ 
				criteria["type"] = type.id;
				criteria["typeLink"] = type.link;
				criteria["typeWord"] = type.word;
			}
        }else criteria["type"] = undefined;

        if(searchParams.has("phrase") && searchParams.get("phrase") !== undefined && searchParams.get("phrase").length > 0)
            criteria['phrase'] = String(searchParams.get("phrase"));
        else criteria["phrase"] = undefined;

        if(isNumber(searchParams.get("priceFrom")) && searchParams.get("priceFrom") > 0)
            criteria['priceFrom'] = parseFloat(searchParams.get("priceFrom"));
        else criteria["priceFrom"] = undefined;

        if(isNumber(searchParams.get("priceTo")) && searchParams.get("priceTo") > 0)
            criteria['priceTo'] = parseFloat(searchParams.get("priceTo"));
        else criteria["priceTo"] = undefined;

        if(isDate(searchParams.get("dateFrom")))
            criteria['dateFrom'] = parseFloat(searchParams.get("dateFrom"));
        else criteria["dateFrom"] = undefined;

        if(isDate(searchParams.get("dateTo")))
            criteria['dateTo'] = parseFloat(searchParams.get("dateTo"));
        else criteria["dateTo"] = undefined;

        if(isNumber(searchParams.get("adults")) && searchParams.get("adults") > 0)
            criteria['adults'] = parseInt(searchParams.get("adults"));
        else criteria["adults"] = undefined;

        if(isNumber(searchParams.get("kids")) && searchParams.get("kids") > 0)
            criteria['kids'] = parseInt(searchParams.get("kids"));
        else criteria["kids"] = undefined;

        if(isNumber(searchParams.get("active")))
            criteria['active'] = parseInt(searchParams.get("active"));
        else criteria["active"] = undefined;
        
        if(
            searchParams.has("radius_marker") && searchParams.get("radius_marker") > 0
            && searchParams.has("lat_marker") && searchParams.get("lat_marker") > 0
            && searchParams.has("lng_marker") && searchParams.get("lng_marker") > 0
        )
        {
            criteria["radiusMarker"] = {
                radius: parseFloat(searchParams.get("radius_marker")),
                radiusTo: undefined,
                lat: parseFloat(searchParams.get("lat_marker")),
                lng: parseFloat(searchParams.get("lng_marker"))
            };
        }else if(
			!searchParams.has("radius_marker")
			&& searchParams.has("lat_marker") && searchParams.get("lat_marker") > 0
			&& searchParams.has("lng_marker") && searchParams.get("lng_marker") > 0
		)
		{
			criteria["radiusMarker"] = {
                radius: undefined,
                radiusTo: undefined,
				lat: parseFloat(searchParams.get("lat_marker")),
				lng: parseFloat(searchParams.get("lng_marker"))
			};
		}else
			criteria["radiusMarker"] = {
				radius: undefined,
				radiusTo: undefined,
				lat: undefined,
				lng: undefined
			};
        
		if(searchParams.has("type") && searchParams.get("type") > 0)
			criteria["type"] = parseInt(searchParams.get("type"));
        
		if(searchParams.has("region") && searchParams.get("region") > 0)
			criteria["region"] = parseInt(searchParams.get("region"));
        
        if(searchParams.has("page") && searchParams.get("page") > 0)
			criteria["page"] = parseInt(searchParams.get("page"));
        else
            criteria["page"] = undefined;

        const pictograms = {};
        searchParams.forEach((value, key) => {
			
            if(key.slice(-4) === "_pic" && isNumber(value) && value > 0)
            	pictograms[key.slice(0, -4)] = parseInt(value);
        })
        criteria["pictograms"] = Object.keys(pictograms).length > 0 ? pictograms : undefined;

        return criteria;
	},
	"attractionsCriteriaToPathname": ({ mode, criteria, attractionsType, regionsType, lang, defaultLang, translate }) => {
		 
		const modes = {};

		modes["attractions"] = 
			`${lang != defaultLang ? `/${lang}` : ``}`
			+ `${isNumber(criteria?.region) && criteria?.region > 0 ? `/${regionsType.find(regionType => regionType.id == criteria?.region).link}` : ``}`
			+ `/${translate.attractions}`
			+ `${isNumber(criteria?.type) && criteria?.type > 0 ? `/${Object.values(attractionsType).find(attractionType => attractionType.id == criteria?.type).link}` : ``}`;

		modes["attractionsMap"] = 
			`${lang != defaultLang ? `/${lang}` : ``}`
			+ `${isNumber(criteria?.region) && criteria?.region > 0 ? `/${regionsType.find(regionType => regionType.id == criteria?.region).link}` : ``}`
			+ `/${translate.attractions}`
			+ `${isNumber(criteria?.type) && criteria?.type > 0 ? `/${Object.values(attractionsType).find(attractionType => attractionType.id == criteria?.type).link}` : ``}`
			+ `/${translate.map}`;

		return modes[mode] !== undefined ? modes[mode] : modes["attractions"];
	},
	"attractionsCriteriaToSearchParams": ({ criteria }) => {
		 
		const search = {};
		
		if(isNumber(criteria?.page) && criteria.page > 1)
			search["page"] = parseInt((criteria.page));

		if(isNumber(criteria?.radiusMarker?.radius) && isNumber(criteria?.radiusMarker?.lat) && isNumber(criteria?.radiusMarker?.lng))
		{
			search["radius_marker"] = parseFloat(criteria.radiusMarker.radius);
			search["lat_marker"] = parseFloat(criteria.radiusMarker.lat);
			search["lng_marker"] = parseFloat(criteria.radiusMarker.lng);
		}
			else if(isNumber(criteria?.radiusMarker?.lat) && isNumber(criteria?.radiusMarker?.lng))
		{
			search["lat_marker"] = parseFloat(criteria.radiusMarker.lat);
			search["lng_marker"] = parseFloat(criteria.radiusMarker.lng);
		}
		
		if(isString(criteria?.phrase))
			search["phrase"] = String(criteria.phrase);

		return search;
	},
	"accommodationsCriteriaToPathname": ({ mode, config, criteria }) => {
		 
		const modes = {};

		modes["accommodations"] = 
			`${config.lang != config.defaultLang ? `/${config.lang}` : ``}`
			+ `${isNumber(criteria?.region) && criteria?.region > 0 ? `/${config.regionsType.find(type => type.id == criteria.region).link}` : ``}`
			+ `/${config.translate.accommodations.link}`
			+ `${isNumber(criteria?.type) && criteria?.type > 0 ? `/${Object.values(config.accommodationsType).find(accommodation => accommodation.id == criteria?.type).link}` : ``}`;

		modes["accommodationsMap"] = 
			`${config.lang != config.defaultLang ? `/${config.lang}` : ``}`
			+ `${isNumber(criteria?.region) && criteria?.region > 0 ? `/${config.regionsType.find(type => type.id == criteria?.region).link}` : ``}`
			+ `/${config.translate.accommodations.link}`
			+ `${isNumber(criteria?.type) && criteria?.type > 0 ? `/${Object.values(config.accommodationsType).find(accommodation => accommodation.id == criteria?.type).link}` : ``}`
			+ `/${config.translate.map.link}`;

		return modes[mode] !== undefined ? modes[mode] : modes["attractions"];
	},
	"accommodationsCriteriaToSearchParams": ({ criteria }) => {
		 
		const search = {};

		if(isNumber(criteria?.kids) && criteria?.kids > 0)
			search["kids"] = parseInt(criteria.kids);

		if(isNumber(criteria?.adults) && criteria?.adults > 0)
			search["adults"] = parseInt(criteria.adults);

		if(isNumber(criteria?.priceFrom) && criteria?.priceFrom > 0)
			search["priceFrom"] = parseFloat(criteria.priceFrom);

		if(isNumber(criteria?.priceTo) && criteria.priceTo > 0)
			search["priceTo"] = parseFloat(criteria.priceTo);

		if(isDate(criteria?.dateFrom))
			search["dateFrom"] = String(criteria.dateFrom);

		if(isDate(criteria?.dateTo))
			search["dateTo"] = String(criteria.dateTo);

		if(isNumber(criteria?.radiusMarker?.radius) && isNumber(criteria?.radiusMarker?.lat) && isNumber(criteria?.radiusMarker?.lng))
		{
			search["radius_marker"] = parseFloat(criteria.radiusMarker.radius);
			search["lat_marker"] = parseFloat(criteria.radiusMarker.lat);
			search["lng_marker"] = parseFloat(criteria.radiusMarker.lng);
		}
			else if(isNumber(criteria?.radiusMarker?.lat) && isNumber(criteria?.radiusMarker?.lng))
		{
			search["lat_marker"] = parseFloat(criteria.radiusMarker.lat);
			search["lng_marker"] = parseFloat(criteria.radiusMarker.lng);
		}

		Object.keys(criteria?.pictograms)
		.filter(pictogram => criteria.pictograms[pictogram] > 0)
		.map(pictogram => search[`${pictogram}_pic`] = parseInt(criteria.pictograms[pictogram]));
		
		if(isString(criteria?.phrase))
			search["phrase"] = String(criteria.phrase);
		
		if(isNumber(criteria?.page) && criteria.page > 1)
			search["page"] = parseInt((criteria.page));

		return search;
	}
};