import React from "react";

//context
import { ConfigProvider } from './providers/config';
import AccommodationsProvider from './providers/accommodations';
import AttractionsProvider from './providers/attractions';

import App from "./App";

export default ({ 
    lang,
    langs,
    defaultLang, 
    slides, 
    promoAttractions,
    attractionsList,
    attractionsCount,
    attractionDescription,
    criteriaAttractions,
    accommodationsList,
    accommodationsCount,
    promoAccommodations,
    accommodationDescription,
    criteriaAccommodations,
    translate,
    host,
    attractionsType,
    accommodationsType,
    regionsType,
    map,
    links,
    device,
    seasons
  }) => {

    return(
        <ConfigProvider
            state={{
                lang,
                defaultLang,
                langs,
                translate,
                links,
                slides,
                host,
                attractionsType,
                accommodationsType,
                regionsType,
                map,
                device,
                translate,
                seasons
            }}
        >
            <AccommodationsProvider 
                state={{
                    'promo': promoAccommodations,
                    'criteria': criteriaAccommodations,
                    'accommodations': accommodationsList,
                    'accommodationsCount': accommodationsCount
                }}
            >
            <AttractionsProvider
                state={{
                    'promo': promoAttractions,
                    'criteria': criteriaAttractions,
                    'attractions': attractionsList,
                    'count': attractionsCount
                }}
            >
                <App 
                    attractionDescription={attractionDescription} 
                    accommodationDescription={accommodationDescription}
                />
            </AttractionsProvider>
            </AccommodationsProvider>
        </ConfigProvider>);
  };