import React, { useState } from 'react';

export const ConfigContext = React.createContext();
export const ConfigConsumer = ConfigContext.Consumer;


export const ConfigProvider = ({ children, state }) => {

    const [resolutionDevice, setResolutionDevice] = useState(state?.device ? state.device : undefined),
    [map, setMap] = useState(state.map);
    
    return (
        <ConfigContext.Provider
            value={{
                lang: state.lang,
                langs: state.langs,
                defaultLang: state.defaultLang,
                translate: state.translate,
                links: state.links,
                slides: state.slides,
                host: state.host,
                attractionsType: state.attractionsType,
                accommodationsType: state.accommodationsType,
                regionsType: state.regionsType,
                map: map,
                setMap: setMap,
                resolutionDevice: resolutionDevice,
                setResolutionDevice: setResolutionDevice,
                seasons: state.seasons
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
};