import React, { lazy, Suspense, useCallback, useContext, useEffect } from 'react';
import {
  Routes,
  Route
} from "react-router-dom";

//context
import { ConfigContext } from './providers/config';

const LandingPage = lazy(() => import('./components/landingPage.js')),
Accommodations = lazy(() => import('./components/accommodations.js')),
Attractions = lazy(() => import('./components/attractions.js')),
Map = lazy(() => import('./components/map.js')),
AccommodationDescription = lazy(() => import('./components/accommodationDescription.js')),
AttractionDescription = lazy(() => import('./components/attractionDescription.js')),
Login = lazy(() => import('./components/login.js')),
AddAccommodation = lazy(() => import('./components/addEditAccommodationObject.js')),
Fallback = () => (
  <div 
    style={{
      position: 'absolute', 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0, 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center'
    }}
  >
    <img src="/logo-duze.png" width="122" height="122" />
  </div>
);

const App = ({ attractionDescription, accommodationDescription }) => {

  const config = useContext(ConfigContext),
  setWindowWidth = (width) => {

    if(width >= 320 && width <= 635)
      return 'smartphone';
    else if(width >= 636 &&  width <= 768)
      return 'tablet';
    else if(width >= 769 && width <= 1024)
      return 'smallDesktop';
    else if(width >= 1025 && width <= 1200)
      return 'desktop';
    else if(width >= 1201)
      return 'largeDesktop';

    return undefined;
  },
  updateDimensions = () => {
    const width = window.innerWidth;
    config.setResolutionDevice(setWindowWidth(width));
  },
  attractionsRoutes = useCallback(() => (
    <>
      <Route 
        index
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Attractions />
          </Suspense>
        }
      />
      <Route 
        path={`:param3`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Attractions />
          </Suspense>
        }
      />
      <Route 
        path={`:param3/${config.translate.map.link}`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Map />
          </Suspense>
        }
      />
      <Route 
        path={`${config.translate.map.link}`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Map />
          </Suspense>
        }
      />
    </>
  ), []),
  accommodationsRoutes = useCallback(() => (
    <>
      <Route 
        index
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Accommodations />
          </Suspense>
        }
      />
      <Route 
        path={`:param3`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Accommodations />
          </Suspense>
        }
      />
      <Route 
        path={`:param3/${config.translate.map.link}`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Map />
          </Suspense>
        }
      />
      <Route 
        path={`${config.translate.map.link}`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Map />
          </Suspense>
        }
      />
      <Route 
        path={`${config.translate.offer.link}-:offerid`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <AccommodationDescription accommodation={accommodationDescription} />
          </Suspense>
        }
      />
    </>
  ), []);

  useEffect(() => {

    window?.addEventListener('resize', updateDimensions);
    
    return () => window?.removeEventListener('resize', updateDimensions);
  }, []);
  
  return (
    <Routes>
      <Route 
        path="/"
      >
        <Route 
          index
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <LandingPage />
            </Suspense>
          }
        />
        <Route 
          path={`${config.translate.login.link}`}
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <Login />
            </Suspense>
          } 
        />
        <Route 
          path={`:param1/${config.translate.login.link}`}
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <Login />
            </Suspense>
          } 
        />
        <Route 
          path={":param1"}
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <LandingPage />
            </Suspense>
          }
        />
        <Route 
          path={":param1/:param2"}
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <LandingPage />
            </Suspense>
          }
        />
        <Route 
          path={`${config.translate.addAccommodation.link}`}
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <AddAccommodation />
            </Suspense>
          }
        />
        <Route 
          path={`:param1/${config.translate.addAccommodation.link}`}
          element={
            <Suspense 
              fallback={<Fallback />}
            >
              <AddAccommodation />
            </Suspense>
          }
        />
      </Route>
      <Route 
        path={`${config.translate.attraction.link}/:title`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <AttractionDescription attractionDescription={attractionDescription} />
          </Suspense>
        }
      />
      <Route 
        path={`:param1/${config.translate.attraction.link}/:title`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <AttractionDescription attractionDescription={attractionDescription} />
          </Suspense>
        }
      />
      <Route 
        path={`${config.translate.attractions.link}`}
      >
        {attractionsRoutes()}
      </Route>
      <Route 
        path={`:param1/${config.translate.attractions.link}`}
      >
        {attractionsRoutes()}
      </Route>
      <Route 
        path={`:param1/:param2/${config.translate.attractions.link}`}
      >
        {attractionsRoutes()}
      </Route>
      <Route 
        path={`${config.translate.accommodations.link}`}
      >
        {accommodationsRoutes()}
      </Route>
      <Route 
        path={`:param1/${config.translate.accommodations.link}`}
      >
        {accommodationsRoutes()}
      </Route>
      <Route 
        path={`:param1/:param2/${config.translate.accommodations.link}`}
      >
        {accommodationsRoutes()}
      </Route>
      <Route 
        path={`${config.translate.accommodation.link}/:offerid`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <AccommodationDescription accommodation={accommodationDescription} />
          </Suspense>
        }
      />
      <Route 
        path={`:param1/${config.translate.accommodation.link}/:offerid`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <AccommodationDescription accommodation={accommodationDescription} />
          </Suspense>
        }
      />
      <Route 
        path={`:param1/${config.translate.map.link}`}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Map />
          </Suspense>
        } 
      />
      <Route 
        path={config.translate.map.link}
        element={
          <Suspense 
            fallback={<Fallback />}
          >
            <Map />
          </Suspense>
        } 
      />
    </Routes>
  );
};

export default App;