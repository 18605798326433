import React, { useState, useCallback } from 'react';

//util
import { isNumber, isString } from '@travel-system/util-common';

export const AttractionsContext = React.createContext();
export const AttractionsConsumer = AttractionsContext.Consumer;

const Provider = ({ children, state }) => {

    const [attractions, setAttractions] = useState({
        'result': state?.attractions ? state.attractions : undefined, 
        'count': state?.count ? state?.count : 0
    }),
    [promoAttractions, setPromoAttractions] = useState(state?.promo !== undefined ? state.promo : undefined),
    [criteria, setCriteria] = useState({
        page: isNumber(state?.criteria?.page) ? state.criteria.page : undefined,
        limit: isNumber(state?.criteria?.limit) ? state.criteria.limit : 15,
        offset: undefined,
        type: isNumber(state?.criteria?.type) ? state.criteria.type : undefined,
        region: isNumber(state?.criteria?.region) ? state.criteria.region : undefined,
        radiusMarker: {
            radius: isNumber(state?.criteria?.radiusMarker?.radius) ? state.criteria.radiusMarker.radius : undefined, 
            radiusTo: isNumber(state?.criteria?.radiusMarker?.radiusTo) ? state.criteria.radiusMarker.radiusTo : undefined, 
            lat: isNumber(state?.criteria?.radiusMarker?.lat) ? state.criteria.radiusMarker.lat : undefined, 
            lng: isNumber(state?.criteria?.radiusMarker?.lng) ? state.criteria.radiusMarker.lng : undefined
        },
        distance: {
            lat: undefined, 
            lng: undefined
        },
        phrase: isString(state?.criteria?.phrase) && state?.criteria?.phrase.length > 0 ? state.criteria.phrase : undefined
    }),
    [map, setMap] = useState({
        latitude: undefined,
        longitude: undefined,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
    }),
    setStateCriteria = useCallback((nextCriteria) => setCriteria({...criteria, ...nextCriteria}), []),
    addAttractions = useCallback(nextAttractions => setAttractions({
        result: Array.isArray(attractions.result) ? attractions.result.concat(nextAttractions.result) : nextAttractions.result, 
        count: nextAttractions.count, 
        countThreads: nextAttractions.countThreads, 
        countMainThreads: nextAttractions.countMainThreads
    }), [attractions]);
    
    return (
        <AttractionsContext.Provider
            value={{
                attractions: attractions,
                setAttractions: setAttractions,
                addAttractions: addAttractions,
                promoAttractions: promoAttractions,
                setPromoAttractions: setPromoAttractions,
                criteria: criteria,
                setCriteria: setStateCriteria,
                map: map,
                setMap: setMap
            }}
        >
            {children}
        </AttractionsContext.Provider>
    )
};

export default Provider;