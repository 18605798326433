import React, { useState, useCallback } from 'react';

//util
import { isNumber, isDate, isString } from '@travel-system/util-common';

export const AccommodationsContext = React.createContext();
export const AccommodationsConsumer = AccommodationsContext.Consumer;

const Provider = ({ children, state  }) => {

    const [accommodations, setAccommodations] = useState({
      'result': state?.accommodations ? state.accommodations : undefined, 
      'count': state?.accommodationsCount ? state.accommodationsCount : 0}),
    [promoAccommodations, setPromoAccommodations] = useState(state?.promo != undefined ? state.promo : undefined),
    [criteriaAvailability, setCriteriaAvailability] = useState({
      type: undefined,
      kids: undefined,
      adults: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      priceFrom: undefined,
      priceTo: undefined
    }),
    [criteria, setCriteria] = useState({
      radiusMarker: {
        radius: isNumber(state?.criteria?.radiusMarker?.radius) ? state.criteria.radiusMarker.radius : undefined, 
        radiusTo: isNumber(state?.criteria?.radiusMarker?.radiusTo) ? state.criteria.radiusMarker.radiusTo : undefined, 
        lat: isNumber(state?.criteria?.radiusMarker?.lat) ? state.criteria.radiusMarker.lat : undefined, 
        lng: isNumber(state?.criteria?.radiusMarker?.lng) ? state.criteria.radiusMarker.lng : undefined
      },
      priceFrom: isNumber(state?.criteria?.priceFrom) ? state.criteria.priceFrom : undefined,
      priceTo: isNumber(state?.criteria?.priceTo) ? state.criteria.priceTo : undefined,
      type: isNumber(state?.criteria?.type) ? state.criteria.type : undefined,
      limit: isNumber(state?.criteria?.limit) ? state.criteria.limit : 15,
      page: isNumber(state?.criteria?.page) ? state.criteria.page : undefined,
      offset: undefined,
      region: isNumber(state?.criteria?.region) ? state.criteria.region : undefined,
      kids: isNumber(state?.criteria?.kids) ? state.criteria.kids : undefined,
      adults: isNumber(state?.criteria?.adults) ? state.criteria.adults : undefined,
      dateFrom: isDate(state?.criteria?.dateFrom) ? state.criteria.dateFrom : undefined,
      dateTo: isDate(state?.criteria?.dateTo) ? state.criteria.dateTo : undefined,
      pictograms: {
        "airconditioning": isNumber(state?.criteria?.pictograms?.airconditioning) ? state.criteria.pictograms.airconditioning : 0, 
        "animals": isNumber(state?.criteria?.pictograms?.animals) ? state.criteria.pictograms.animals : 0, 
        "attractionplace": isNumber(state?.criteria?.pictograms?.attractionplace) ? state.criteria.pictograms.attractionplace : 0, 
        "beach": isNumber(state?.criteria?.pictograms?.beach) ? state.criteria.pictograms.beach : 0, 
        "bike": isNumber(state?.criteria?.pictograms?.bike) ? state.criteria.pictograms.bike : 0, 
        "billiard": isNumber(state?.criteria?.pictograms?.billiard) ? state.criteria.pictograms.billiard : 0, 
        "bowling": isNumber(state?.criteria?.pictograms?.bowling) ? state.criteria.pictograms.bowling : 0, 
        "cafe": isNumber(state?.criteria?.pictograms?.cafe) ? state.criteria.pictograms.cafe : 0, 
        "cake": isNumber(state?.criteria?.pictograms?.cake) ? state.criteria.pictograms.cake : 0, 
        "campfire": isNumber(state?.criteria?.pictograms?.campfire) ? state.criteria.pictograms.campfire : 0, 
        "camping": isNumber(state?.criteria?.pictograms?.camping) ? state.criteria.pictograms.camping : 0, 
        "canoe": isNumber(state?.criteria?.pictograms?.canoe) ? state.criteria.pictograms.canoe : 0, 
        "conference": isNumber(state?.criteria?.pictograms?.conference) ? state.criteria.pictograms.conference : 0, 
        "creditcards": isNumber(state?.criteria?.pictograms?.creditcards) ? state.criteria.pictograms.creditcards : 0, 
        "dances": isNumber(state?.criteria?.pictograms?.dances) ? state.criteria.pictograms.dances : 0, 
        "disability": isNumber(state?.criteria?.pictograms?.disability) ? state.criteria.pictograms.disability : 0, 
        "eating": isNumber(state?.criteria?.pictograms?.eating) ? state.criteria.pictograms.eating : 0, 
        "fireplace": isNumber(state?.criteria?.pictograms?.fireplace) ? state.criteria.pictograms.fireplace : 0, 
        "fitness": isNumber(state?.criteria?.pictograms?.fitness) ? state.criteria.pictograms.fitness : 0, 
        "fruits": isNumber(state?.criteria?.pictograms?.fruits) ? state.criteria.pictograms.fruits : 0, 
        "heating": isNumber(state?.criteria?.pictograms?.heating) ? state.criteria.pictograms.heating : 0, 
        "jacuzzi": isNumber(state?.criteria?.pictograms?.jacuzzi) ? state.criteria.pictograms.jacuzzi : 0, 
        "kids": isNumber(state?.criteria?.pictograms?.kids) ? state.criteria.pictograms.kids : 0, 
        "kitchen": isNumber(state?.criteria?.pictograms?.kitchen) ? state.criteria.pictograms.kitchen : 0, 
        "lake": isNumber(state?.criteria?.pictograms?.lake) ? state.criteria.pictograms.lake : 0, 
        "nature": isNumber(state?.criteria?.pictograms?.nature) ? state.criteria.pictograms.nature : 0, 
        "parking": isNumber(state?.criteria?.pictograms?.parking) ? state.criteria.pictograms.parking : 0, 
        "pingpong": isNumber(state?.criteria?.pictograms?.pingpong) ? state.criteria.pictograms.pingpong : 0, 
        "playground": isNumber(state?.criteria?.pictograms?.playground) ? state.criteria.pictograms.playground : 0, 
        "playingfield": isNumber(state?.criteria?.pictograms?.playingfield) ? state.criteria.pictograms.playingfield : 0, 
        "restaurant": isNumber(state?.criteria?.pictograms?.restaurant) ? state.criteria.pictograms.restaurant : 0, 
        "river": isNumber(state?.criteria?.pictograms?.river) ? state.criteria.pictograms.river : 0, 
        "sauna": isNumber(state?.criteria?.pictograms?.sauna) ? state.criteria.pictograms.sauna : 0, 
        "solarium": isNumber(state?.criteria?.pictograms?.solarium) ? state.criteria.pictograms.solarium : 0, 
        "swimmingpool": isNumber(state?.criteria?.pictograms?.swimmingpool) ? state.criteria.pictograms.swimmingpool : 0, 
        "tennis": isNumber(state?.criteria?.pictograms?.tennis) ? state.criteria.pictograms.tennis : 0, 
        "trees": isNumber(state?.criteria?.pictograms?.trees) ? state.criteria.pictograms.trees : 0, 
        "weddings": isNumber(state?.criteria?.pictograms?.weddings) ? state.criteria.pictograms.weddings : 0, 
        "wifi": isNumber(state?.criteria?.pictograms?.wifi) ? state.criteria.pictograms.wifi : 0
      },
      phrase: isString(state?.criteria?.phrase) && state?.criteria?.phrase.length > 0 ? state.criteria.phrase : undefined
    }),
    [map, setMap] = useState({
      latitude: undefined,
      longitude: undefined,
      latitudeDelta: 0.0922,
      longitudeDelta: 0.0421,
    }),
    setStateCriteria = useCallback(nextCriteria => setCriteria({...criteria, ...nextCriteria}), []),
    addAccommodations = useCallback(nextAccommodations => setAccommodations({
        result: accommodations.result.concat(nextAccommodations), 
        count: accommodations.count
    }), [accommodations]);
    
    return (
        <AccommodationsContext.Provider
            value={{
                accommodations: accommodations,
                setAccommodations: setAccommodations,
                addAccommodations: addAccommodations,
                promoAccommodations: promoAccommodations,
                setPromoAccommodations: setPromoAccommodations,
                criteria: criteria,
                criteriaAvailability: criteriaAvailability,
                setCriteriaAvailability: setCriteriaAvailability,
                setCriteria: setStateCriteria,
                map: map,
                setMap: setMap
            }}
        >
            {children}
        </AccommodationsContext.Provider>
    )
};

export default Provider;