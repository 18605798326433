import React from 'react';
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import ProvidersApp from "./client/providersConfiguration";

import './client/styles/stylesLandingPage.css';
import './client/styles/attractions.css';
import './client/styles/accommodations.css';
import './client/styles/map.css';
import './client/styles/attractionDescription.css';
import './client/styles/accommodationDescription.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './client/styles/index.css';

//import { ConfigProvider as ConfigProviderAntD } from 'antd';
//import plPL from 'antd/lib/locale/pl_PL';
//import deDE from 'antd/lib/locale/de_DE';
//import enUS from 'antd/lib/locale/en_US';

/*const chioceLang = {
  'en': enUS,
  'pl': plPL,
  'de': deDE
};*/

hydrateRoot(document.getElementById("root"),
  <BrowserRouter>
    <ProvidersApp {...window?.assetManifest} />
  </BrowserRouter>
);